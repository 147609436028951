<!-- 新增登记  By阿旭 -->
<template>
  <div class="main">
    <div class="top">
      <p class="top_left">
        <i class="el-icon-back" style="margin-right: 20px;" @click="$router.go(-1)"></i>
        <span>{{ title }}</span>
      </p>
      <p class="top_right">
        <span class="xinxin">*</span> 为必填项
      </p>
    </div>
    <el-divider></el-divider>

    <div class="content">
      <div class="xxinput">
        <div class="content_residentinformationForm">
          <el-form :rules="rules" ref="redformRef" :model="redform" label-width="140px">
            <div class="content-box">
              <span class="blue"></span>
              <p>居民信息</p>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="姓名：" prop="name">
                <el-input style="width:90%" v-model="redform.name" placeholder="请输入姓名" />
              </el-form-item>
              <el-form-item class="formitem" label="证件类型：" prop="cardType">
                <div style="display: flex">
                  <el-select style="width:75%" v-model="redform.cardType" placeholder="证件类型" @change="handelZhengjian">
                    <el-option v-for="item in typeCertificate" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                  <el-button style="margin-left: 10px;" type="info" @click="readIdCardInfo">读卡</el-button>
                </div>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="证件号：" prop="cardNo">
                <el-input style="width:90%" v-model="redform.cardNo" placeholder="请输入证件号"
                  @blur="idCodeCount(redform.cardNo)" />
              </el-form-item>
              
              <el-form-item class="formitem" label="性别：" prop="sex">
                <el-radio-group style="width:90%" v-model="redform.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="年龄：" prop="age">
                <el-input type="number" style="width:90%" v-model="redform.age" placeholder="请输入年龄" />
              </el-form-item>
              <el-form-item class="formitem" label="联系电话：" prop="linkPhone">
                <el-input style="width:90%" :maxlength="11" v-model="redform.linkPhone" placeholder="请输入联系电话"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')" @blur="salaryChange" />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="国籍：" prop="country">
                <el-select filterable placeholder="请选择国籍" style="width: 90%" v-model="redform.country">
                  <el-option v-for="item in nationalityselet" :key="item.label" :label="item.label" :value="item.label">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formitem" label="民族：">
                <el-select style="width:90%" class="top_left_inputnationality" v-model="redform.nation"
                  placeholder="民族">
                  <el-option v-for="item in nationSelect" :key="item.dictCode" :label="item.dictLabel"
                    :value="item.dictLabel"></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="content-box">
              <span class="blue"></span>
              <p>人群分类</p>
            </div>
            <div class="content-item">
              <el-form-item class="formitem" label="选择人群分类：" prop="crowdTypeId">
                <el-select style="width:90%" v-model="redform.crowdTypeId" placeholder="选择人群分类"
                  @change="popchange1(redform.crowdTypeId,redform.crowdType)">
                  <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </div>


            <!-- 引入子组件 入住表单为1，2，3，6 中高风险，市外入境，境外航班，特定时空 -->
            <!-- 中高风险 -->
            <temporaryIsolation ref="isolationRef"
            v-if="this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6"
              :redform="redform"
              :crowdTypeId="redform.crowdTypeId" @changReachDate="changReachDate" :checkForm="checkForm"/>

              <!-- 引入子组件，入住表单为4，5 密切接触，次密接人群 -->
            <!-- 密接，次密接 -->
            <closeContact ref="closeContactRef" v-if="this.checkForm == 4 || this.checkForm == 5"
              @changeContiguityDate="changeContiguityDate" :hightemporaryformObj="hightemporaryformObj"
              :crowdTypeId="redform.crowdTypeId"
              :checkForm="checkForm"
               />
            <!-- 引入子组件，入住表单为9  其他人群 -->
            <!--其他人群组件-->
            <restCrowd ref="restCrowdRef" :redform="redform" v-if="this.checkForm == 9"  @changReachDate="changReachDate" />
            
            <div v-show="redform.crowdTypeId">
              <div class="content-box">
                <span class="blue"></span>
                <p>隔离时间</p>
              </div>

              <div class="content-item">
                <el-form-item class="formitem" label="隔离时间类别：" prop="startTimeType">
                  <el-select style="width:90%" v-model="redform.startTimeType" disabled placeholder="请选择">
                    <el-option v-for="item in quarantineTimeTypeList" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item class="formitem" label="开始隔离时间：" prop="startTime">
                  <el-date-picker style="width:90%" type="datetime" v-model="redform.startTime"
                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
                </el-form-item>
              </div>

              <div class="content-item">
                <el-form-item class="formitem" label="入住时间：" prop="regTime">
                  <el-date-picker style="width:90%" type="datetime" :clearable="false" v-model="redform.regTime"
                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
                </el-form-item>
              </div>
            </div>

            <div v-show="redform.crowdTypeId">
              <div class="content-box">
                <span class="blue"></span>
                <p>检测规则</p>
              </div>

              <div class="content-item">
                <el-form-item class="formitem" label="检测规则：" prop="customDetectionStatus">
                  <el-select style="width:90%" v-model="redform.customDetectionStatus" @change="changeDetectionType"
                    placeholder="请选择">
                    <el-option v-for="item in detectionTypeList" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item class="formitem" label="实际隔离天数：" prop="days" v-if="redform.customDetectionStatus != 0">
                  <el-input style="width:90%;" type="number" v-model="redform.days" />
                </el-form-item>
              </div>

              <div class="detection-rule" v-if="redform.customDetectionStatus != 0 && redform.days">
                <div style="width: 50%;">
                  <div v-for="(item, index) in redform.nucleicAcidNode" :key="index">
                    <el-form-item class="formitem" :label="'核酸检测节点' + (index + 1) + '：'"
                      :prop="'nucleicAcidNode.' + index + '.value'"
                      :rules="{ required: true, message: '核酸检测不能为空', trigger: 'change' }">
                      <div style="display: flex;">
                        <el-input-number v-model="item.value" style="width: 30%; margin-right:16px"
                          controls-position="right" @change="handleChange('核酸检测节点', index)" :min="1"
                          :max="Number(redform.days)" />
                        <div class="node-time" v-if="item.value">预估核酸检测时间 {{ item.time }}</div>
                        <el-button type="text" style="color: #ff6565;" v-show="index != 0"
                          @click.prevent="removeDomain(index, '核酸')">删除</el-button>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="btn" v-if="redform.nucleicAcidNode.length < redform.days">
                    <span @click="addDomain('核酸')">添加下一个核酸检测节点</span>
                  </div>
                </div>

                <div style="width: 50%;" v-if="isAntibodyNode">
                  <el-form-item class="formitem" v-for="(item, index) in redform.antibodyNode"
                    :label="'抗体检测节点' + (index + 1) + '：'" :key="index" :prop="'antibodyNode.' + index + '.value'"
                    :rules="{ required: true, message: '抗体检测不能为空', trigger: 'change' }">
                    <div style="display: flex;">
                      <el-input-number v-model="item.value" style="width: 30%; margin-right:16px"
                        controls-position="right" @change="handleChange('抗体检测节点', index)" :min="1"
                        :max="Number(redform.days)" />
                      <div class="node-time" v-if="item.value">预估抗体检测时间 {{ item.time }}</div>
                      <el-button type="text" style="color: #ff6565;" v-show="index != 0"
                        @click.prevent="removeDomain(index, '抗体')">删除</el-button>
                    </div>
                  </el-form-item>

                  <div class="btn" v-if="redform.antibodyNode.length < redform.days">
                    <span @click="addDomain('抗体')">添加下一个抗体检测节点</span>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <div class="bottom">
      <el-button size="medium" type="primary" plain
        v-if="redform.crowdTypeId && redform.customDetectionStatus != 0 && redform.days" @click="addAntibody">{{
            antibodyText
        }}</el-button>
      <el-button size="medium" :disabled="!pain" :type="paintype" v-loading.fullscreen.lock="fullscreenLoading" plain
        @click="commit">提交</el-button>
      <el-button size="medium" :disabled="!pain" :type="paintype" v-loading.fullscreen.lock="fullscreenLoading" plain
        @click="bccommit">保存后继续创建</el-button>
      <el-button size="medium" type plain @click="resetForm">重置</el-button>
    </div>
  </div>
</template>

<script>
import temporaryIsolation from "../../components/NewAddForm/temporaryIsolation"; // 前四个人群和第9、10人群分类 共用此组件
import closeContact from "../../components/NewAddForm/closeContact"; // 6、7、8人群共用此组件
import restCrowd from "../../components/NewAddForm/restCrowd"; // 其他人群组件
import readIdCardUtil from '@/utils/readIdCardUtil'
import {
  updateAndCheck,
  infoRegLiveScreen,
  getCrowdTypeList
} from "@/api/RuleManagement/crowdAnalysis";
import { isolationRegister } from "@/api/QuarantineRegistration/quarantineRegistrationpop";
import constants from "../../common/constants";
import { getIdNumberInfo } from "@/utils/utils";
import { isIDCard, isPhone } from "@/utils/validate";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: { temporaryIsolation, closeContact, restCrowd },
  data() {
    return {
      redform: {
        arrivalTime:'',
        contactTime:'',
        sex: "",
        crowdTypeId:'',
        crowdType:"",
        cardType: '1',
        country: "中国大陆",
        nation: "汉族",
        startTimeType: "",
        startTime: "",
        customDetectionStatus: "0",
        days: "",
        nucleicAcidNode: [],
        antibodyNode: [],
        regTime: new Date()
      },
      typepopSelect: [],
      title: "新增隔离登记",
      antibodyText: "添加抗体检测",
      pain: false, //提交按钮
      paintype: "info", //按钮颜色
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur,change" }],
        cardType: [
          { required: true, message: "请选择证件类型", trigger: "change,change" }
        ],
        cardNo: [{ required: true, message: "请输入证件号", trigger: "blur,change" }],
        age: [{ required: true, message: "请填写年龄", trigger: "blur,change" }],
        linkPhone: [
          { required: true, message: "请填写手机号", trigger: "blur,change" },
          { min: 11, max: 11, message: '长度11个字符', trigger: "blur" },
        ],
        country: [{ required: true, message: "请选择国籍", trigger: "change" }],
        crowdTypeId: [
          { required: true, message: "请选择人群分类", trigger: "change" }
        ],
        startTimeType: [
          { required: true, message: "请选择隔离时间类别", trigger: "change" }
        ],
        startTime: [
          { required: true, message: "请选择开始隔离时间", trigger: "change" }
        ],
        customDetectionStatus: [
          { required: true, message: "请选择检测规则", trigger: "change" }
        ],
        regTime: [
          { required: true, message: "请选择入住时间", trigger: "change" }
        ],
        days: [
          { required: true, message: "请输入实际隔离天数", trigger: "blur" }
        ]
      },
      nationalityselet: JSON.parse(localStorage.getItem("nationalityselet")), //国籍选项
      typeCertificate: constants.TYPE_CERTIFICATE.filter((item, i) => i !== 0), //身份证类型
      quarantineTimeTypeList: constants.QUARANTINE_TIME_TYPE, // 隔离时间类别
      detectionTypeList: constants.DETECTION_TYPE, // 检测规则
      savecontinue: false, //保存后继续创建
      isAntibodyNode: false, // 显示抗体检测节点
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      fullscreenLoading: false, // 提交时显示loading
      roles: JSON.parse(localStorage.getItem("roles")),

      reachDate: "", // 保存抵渝时间
      contiguityDate: "", // 保存密接时间,

      hightemporaryformObj: {
        closelyName: "",
        closelyTime: ""
      },
      selectId:"",
      checkForm:'',
      seletList:[]
    };
  },

  computed: {
    ...mapState({
      isolateInfo: state => state.user.isolateInfo, // 隔离点信息
      nationSelect: state => state.user.nationSelect, // 民族
      // typepopSelect: state => state.user.typepopSelect,  // 人群分类
    })
  },

  watch: {
    redform: {
      handler(newVal) {
        // 已经选择 抵渝时间 监听开始隔离时间
        if (this.reachDate) {
          if (newVal.startTime === this.reachDate) {
            this.redform.startTimeType = "1";
          } else {
            this.redform.startTimeType = "3";
          }
        }

        // 已经选择 密接时间 监听开始隔离时间
        if (this.contiguityDate) {
          if (newVal.startTime === this.contiguityDate) {
            this.redform.startTimeType = "2";
          } else {
            this.redform.startTimeType = "3";
          }
        }
      },
      deep: true
    }
  },

  created() {
    this.typepopSelect = this.$store.state.user.typepopSelect
    // this.rqtype()
    // 初始化身份证读卡器
    readIdCardUtil.initWebSocket({
      onMessage: (req) => {
        let idCardInfo = readIdCardUtil.dataFilter(req.data)
        if (idCardInfo && idCardInfo.length > 0) {
          this.redform.name = idCardInfo[1]
          this.redform.cardNo = idCardInfo[9]
          this.redform.nation = idCardInfo[5] + '族'
          this.idCodeCount(this.redform.cardNo)
        } else {
          this.$message({
            message: '读取身份证信息失败',
            type: 'warning'
          })
        }
      }
    });
  },

  mounted() {
    // 接收核查处理点击进来的rowdata
    let { rowdata } = this.$route.query;
    // console.log(rowdata, 'newRegistrationPage接收')
    if (rowdata != "add") {
      this.redform = { ...this.redform, ...rowdata };

      // this.redform.crowdTypeId = +this.redform.crowdTypeId;
      this.redform.customDetectionStatus =
        this.redform.customDetectionStatus + "";
      this.title = "核查处理";

      // console.log(555555555, rowdata.updTime);

      this.redform.crowdType = rowdata.crowdType
      this.redform.contactTime = rowdata.contactTime ? rowdata.contactTime : rowdata.closelyTime;
      this.redform.startTime = rowdata.startTime?rowdata.startTime:rowdata.contactTime;
      // this.redform.startTimeType
      // console.log(66666666666, this.redform, rowdata);
      this.hightemporaryformObj = {
        closelyName: rowdata.caseName ? rowdata.caseName : rowdata.closelyName,
        closelyTime: rowdata.contactTime ? rowdata.contactTime : rowdata.closelyTime,
      };

      
      this.hcdata(); //核查接口
      this.popchange(this.redform.crowdTypeId, 'created');
      // console.log(this.checkForm,55555555);
      
      this.$nextTick(() => {
        // console.log('nextTick,nextTick');
        if (
          this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6
        ) {
          this.renderComponentData("isolationRef");
        } else if (this.checkForm == 4 || this.checkForm == 5) {
          // this.$refs.closeContactRef.hightemporaryform = this.redform;
        }else if (this.checkForm == 9) {
          this.renderComponentData("restCrowdRef");
        }
      });
    } 
    // console.log(this.redform.crowdType,'this.redform.crowdType,===>406');

    this.getCrowdTypeList();
    // console.log(this.redform);
  },

  methods: {
    handelZhengjian(res){
      // console.log(res);
      this.redform.cardType = res
    },
    selectClass(res){
      let id = ''
      let form = ''

      this.typepopSelect.forEach(item=>{
        if(res == item.code){
          id = item.id
          form = item.checkForm
        }
      })
      this.selectId = id
      // console.log(id);
    },
    //手机号
    salaryChange(e) {
      this.redform.linkPhone = e.target.value;
    },
    // 渲染组件数据（此处渲染出错，注释掉，暂时不删除）
    renderComponentData(key) {
      // if (this.redform.livingAreaCode) {
      //   this.$refs[
      //     key
      //   ].formInfo.livingAreaCode = this.redform.livingAreaCode.split(",");
      // }
      // if (this.redform.cityEntryCode) {
      //   this.$refs[
      //     key
      //   ].formInfo.cityEntryCode = this.redform.cityEntryCode.split(",");
      // }
      // if (this.redform.householdPlaceCode) {
      //   this.$refs[
      //     key
      //   ].formInfo.householdPlaceCode = this.redform.householdPlaceCode.split(
      //     ","
      //   );
      // }
      // if (this.redform.destinationCode) {
      //   this.$refs[
      //     key
      //   ].formInfo.destinationCode = this.redform.destinationCode.split(",");
      // }

      // this.$refs[key].formInfo.livingCountry = this.redform.livingCountry;
      // this.$refs[key].formInfo.riskLevel = this.redform.riskLevel;
      // this.$refs[key].formInfo.arrivalTime = this.redform.arrivalTime;
      // this.$refs[key].formInfo.contactTime = this.redform.contactTime;
      // this.$refs[key].formInfo.caseName = this.redform.caseName;
      // this.$refs[
      //   key 
      // ].formInfo.destinationDetail = this.redform.destinationDetail;
      // this.$refs[
      //   key
      // ].formInfo.destinationStreet = this.redform.destinationStreet;
      // this.$refs[key].formInfo.coughDetail = this.redform.coughDetail;
      // this.$refs[key].formInfo.portEntry = this.redform.portEntry;
      // this.$refs[key].formInfo.traffic = this.redform.traffic;
      // this.$refs[key].formInfo.leaveTime = this.redform.leaveTime;
      // this.$refs[key].formInfo.destination = this.redform.destination;
      // this.$refs[key].formInfo.seatNo = this.redform.seatNo;
      // this.$refs[key].formInfo.householdPlace = this.redform.householdPlace;
      // this.$refs[key].formInfo.trafficNo = this.redform.trafficNo;
      // this.$refs[key].formInfo.livingArea = this.redform.livingArea;
      // this.$refs[key].formInfo.cityEntry = this.redform.cityEntry;
    },
    async rqtype(crowdTypeId, crowdType) {
      // console.log(this.$store.state.user.typepopSelect);
      // console.log(this.typepopSelect);
      // console.log(55555);
      let data = this.$store.state.user.typepopSelect
      // let { data } = await getCrowdTypeList();
      // if (data.code == 200) {
        this.seletList = data
        let id = ''
      let myform = ''
      // console.log(crowdTypeId);
      // console.log(this.seletList);
      this.seletList.forEach(item=>{
        if(crowdTypeId == item.id){
          id = item.id
          myform = item.checkForm
        }
      })
      // console.log(myform,888888);/
      this.selectId = id
      this.checkForm = myform
      this.pain = true;
      this.paintype = "primary";

      // 根据人群分类设置 隔离时间类别
      if (
        this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6
      ) {
        this.redform.startTimeType = "1";
      } 
      else if (this.checkForm == 4||this.checkForm==5) {
        this.redform.startTimeType = "3";
      } 
      else {
        this.redform.startTimeType = "2";
      }

      // 人群分类切换时 清空隔离时间中的开始隔离时间
      // if(type && type == 'created'){

      // }else{
      //   this.redform.startTime = "";
      // }
      this.reachDate = "";
      this.contiguityDate = "";

      this.typepopSelect.forEach(item => {
        if (item.id == crowdTypeId) {
          this.redform.crowdTypeName = item.ruleName;
        }
      });
      // } 
      // else {
      //   this.$message.error(data.msg);
      // }
    },
    // 人群类别
    popchange(crowdTypeId, crowdType) {
      this.rqtype(crowdTypeId, crowdType)
      
    },
    popchange1(crowdTypeId, crowdType) {
      let id = ''
      let myform = ''
      let type = ''
      this.typepopSelect.forEach(item=>{
        if(crowdTypeId == item.id){
          id = item.id
          type = item.code
          myform = item.checkForm
        }
      })
      this.redform.crowdType = type
      this.selectId = id
      this.checkForm = myform
      // console.log(this.checkForm);
      this.pain = true;
      this.paintype = "primary";

      // 根据人群分类设置 隔离时间类别
      if (
        this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6
      ) {
        this.redform.startTimeType = "1";
      } 
      else if (this.checkForm == 4||this.checkForm==5) {
        this.redform.startTimeType = "3";
      } 
      else {
        this.redform.startTimeType = "2";
      }

      // 人群分类切换时 清空隔离时间中的开始隔离时间
      // if(type && type == 'created'){

      // }else{
      //   this.redform.startTime = "";
      // }
      this.reachDate = "";
      this.contiguityDate = "";

      this.typepopSelect.forEach(item => {
        if (item.id == crowdTypeId) {
          this.redform.crowdTypeName = item.ruleName;
        }
      });
    },

    // 保存后继续创建
    bccommit(redform) {
      this.savecontinue = true;
      this.commit(redform);
    },

    // 提交
    commit() {
      this.componentEvent("提交");
      // console.log(this.redform);
      // console.log(this.redform.caseName);
      if(this.checkForm == 4 || this.checkForm == 5){
        if(this.redform.contactTime == null|| this.redform.closelyName == null){
        this.$message.error("登记信息没有填写完整");
        return false
        }
      }
      
      // console.log(333333333333333, this.$refs.redformRef);
      this.$refs.redformRef.validate(valid => {
        if (valid) {
          this.commitapi();
        } else {
          this.$message.warning("登记信息没有填写完整");
          return false;
        }
      });
    },

    // 提交调用接口
    async commitapi() {
      if (this.title != "核查处理") {
        this.redform.regChannel = 0;
        this.redform.isolationPointId = this.isolateInfo.id;
        this.redform.orgCode = this.userdoctor.orgCode;
      }

      let form = {
        ...this.redform,
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode
      };

      form.regTime = moment(form.regTime).format("YYYY-MM-DD HH:mm:ss");

      // 证件类型选择为身份证才验证身份证格式
      if (form.cardType == 1) {
        if (form.cardNo.length < 18) {
          this.$message.warning("请输入正确的证件号码");
          return;
        } else if (!isIDCard(form.cardNo)) {
          this.$message.warning("证件号码格式不正确");
          return;
        }
      }

      // if (form.linkPhone.length < 11) {
      //   this.$message.warning("请输入正确的手机号码");
      //   return;
      // } else if (!isPhone(form.linkPhone)) {
      //   this.$message.warning("手机号码格式不正确");
      //   return;
      // }

      if (form.destinationCode) {
        form.destinationCode = form.destinationCode.toString();
      }
      if (form.householdPlaceCode) {
        form.householdPlaceCode = form.householdPlaceCode.toString();
      }
      if (form.livingAreaCode) {
        form.livingAreaCode = form.livingAreaCode.toString();
      }
      if (form.cityEntryCode) {
        form.cityEntryCode = form.cityEntryCode.toString();
      }

      let params = {
        reg: form,
        regLiveScreen: form,
        customDetectionInfoEntity: {
          hospitalId: this.userdoctor.hospitalId,
          orgCode: this.userdoctor.orgCode
        }
      };

      // 如果检测规则 类型是自定义规则
      let {
        nucleicAcidNode,
        antibodyNode,
        customDetectionStatus,
        days
      } = this.redform;
      if (customDetectionStatus != 0) {
        let covidLabDays = [],
          antibodyLabDays = [];
        nucleicAcidNode.forEach(item => {
          covidLabDays.push(item.value);
        });
        antibodyNode.forEach(item => {
          antibodyLabDays.push(item.value);
        });

        params.customDetectionInfoEntity.days = days;
        params.customDetectionInfoEntity.covidLabDays = covidLabDays.toString();
        params.customDetectionInfoEntity.antibodyLabDays = antibodyLabDays.toString();
      }

      let requestApi =
        this.title == "核查处理" ? updateAndCheck : isolationRegister;
      this.fullscreenLoading = true;

      params.reg.crowdTypeId = this.redform.crowdTypeId;
      params.reg.crowdType = this.redform.crowdType;
      // console.log(params);
      // console.log(this.redform,3333);

      params.reg.arrivalTime = this.redform.contactTime?this.redform.contactTime:this.redform.arrivalTime
      params.reg.startTime = this.redform.startTime
      // console.log(params);
      let { data } = await requestApi(params);
      if (data.code == 200) {
        if (this.savecontinue == false) {
          //是否是提交还是提交继续
          this.$router.go(-1);
          this.$message.success("提交成功！");
        } else {
          this.resetForm();
          this.savecontinue = false;
          this.$message.success("提交成功！");
        }
      }
      this.fullscreenLoading = false;
    },

    // 重置
    resetForm() {
      let crowdTypeId = this.redform.crowdTypeId;
      this.$refs.redformRef.resetFields();
      this.redform.country = "中国大陆";
      this.redform.nation = "汉族";
      this.redform.customDetectionStatus = "0";
      this.redform.crowdTypeId = crowdTypeId;
      this.redform.nucleicAcidNode = [{ value: "" }];
      this.redform.regTime = "";
      this.componentEvent("重置");
    },

    // 调用组件事件
    componentEvent(type) {
      let { crowdTypeId } = this.redform;
      // console.log(type, crowdTypeId);

      // 前四类人群
      if (
        this.checkForm == 1 || this.checkForm == 2 || this.checkForm == 3 || this.checkForm == 6
      ) {
        if (type === "提交") this.$refs.isolationRef.comm();
        if (type === "重置") this.$refs.isolationRef.resetf();
        this.redform = {...this.redform,...this.$refs.isolationRef.formInfo };
      } else if (this.checkForm == 4 || this.checkForm == 5) {
        if (type === "提交") this.$refs.closeContactRef.comm();
        if (type === "重置") this.$refs.closeContactRef.resetf();
        this.redform = {
          ...this.redform,
          ...this.$refs.closeContactRef.hightemporaryform
        };
        // console.log(77777, this.redform);
      } else if (this.checkForm == 9) {
        if (type === "提交") this.$refs.restCrowdRef.comm();
        if (type === "重置") this.$refs.restCrowdRef.resetf();
        this.redform = {...this.redform, ...this.$refs.restCrowdRef.formInfo };
      }
    },

    // 删除节点
    removeDomain(index, type) {
      if (index != 0) {
        if (type === "核酸") {
          this.redform.nucleicAcidNode.splice(index, 1);
        } else if (type === "抗体") {
          this.redform.antibodyNode.splice(index, 1);
        }
      }
    },

    // 添加节点
    addDomain(type) {
      if (type === "核酸") {
        this.redform.nucleicAcidNode.push({});
      } else if (type === "抗体") {
        this.redform.antibodyNode.push({});
      }
    },

    // 节点内容改变回调
    handleChange(type, index) {
      let { nucleicAcidNode, antibodyNode, regTime } = this.redform;
      if (type === "核酸检测节点") {
        nucleicAcidNode.forEach((item, i) => {
          if (i == index) {
            let num = item.value - 1;
            let predictTime = moment(regTime)
              .add("days", num)
              .format("YYYY-MM-DD HH:mm:ss");
            item.time = predictTime;
          }
        });
      } else if (type === "抗体检测节点") {
        antibodyNode.forEach((item, i) => {
          if (i == index) {
            let num = item.value - 1;
            let predictTime = moment(regTime)
              .add("days", num)
              .format("YYYY-MM-DD HH:mm:ss");
            item.time = predictTime;
          }
        });
      }
    },

    // 规则类型 回调
    changeDetectionType() {
      let { customDetectionStatus } = this.redform;
      if (customDetectionStatus == 1) {
        this.redform.nucleicAcidNode = [{}];
        this.redform.antibodyNode = [];
      }
    },

    // 添加抗体检测节点
    addAntibody() {
      this.isAntibodyNode = !this.isAntibodyNode;
      this.redform.antibodyNode = [{}];
      this.antibodyText = this.isAntibodyNode ? "删除抗体检测" : "添加抗体检测";
    },

    // 抵渝时间
    changReachDate(date) {
      this.reachDate = date;
      this.redform.contactTime = this.reachDate ? this.reachDate : "";
      this.redform.arrivalTime = this.reachDate ? this.reachDate : ""
      this.redform.startTime = this.reachDate ? this.reachDate : ""
    },

    // 密接时间
    changeContiguityDate(date) {
      this.contiguityDate = date;
      this.redform.contactTime = this.contiguityDate ? this.contiguityDate : "";
      this.redform.startTime = this.contiguityDate ? this.contiguityDate : "";
    },

    // 查询入住筛查信息
    async hcdata() {
      let params = {
        regId: this.redform.id
      };
      try {
        let { data } = await infoRegLiveScreen({ params });
        this.redform = Object.assign({ ...this.redform, ...data.data });
      } catch (error) { }
    },

    // 身份证计算
    idCodeCount(id) {
      if (this.redform.cardType == 1) {
        if (id && id.length == 18) {
          let obj = getIdNumberInfo(id);
          this.redform.sex = obj.sex == 0 ? "1" : "0";
          this.redform.age = obj.age;
        } else {
          this.redform.sex = "";
          this.redform.age = "";
        }
      }
    },

    // 人群分类
    async getCrowdTypeList() {
      const { data } = await getCrowdTypeList();
      if (data.code == "200") {
        this.typepopSelect = data.data;
      }
    },
    readIdCardInfo() {
      if (this.redform.cardType === "1") {
        readIdCardUtil.websocket.send("{\"Method\":\"IdReadCard\"}")
      } else {
        this.$message.warning('证件类型请选择身份证')
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;

  .top {
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .top_left {
      font-size: 18px;
      font-weight: bolder;
      color: #666666;
    }

    .top_right {
      margin-left: 20px;
      font-size: 15px;
      color: #9a9a9a;

      .xinxin {
        color: #ff5050;
      }
    }
  }

  .content {
    .content_residentinformationForm {
      margin-top: 40px;
      width: 100%;

      .content-box {
        display: flex;
        align-items: center;
        margin: 30px 0;
      }

      .content-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .detection-rule {
        display: flex;

        .formitem {
          width: 100%;
        }

        .btn {
          font-size: 14px;
          color: #288cfa;
          text-align: center;
          margin-bottom: 20px;

          span {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.formitem {
  width: 50%;

  .node-time {
    color: #999999;
    margin-right: 20px;
  }
}

.xxinput {
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
}
</style>
